<template>
  <div class="general-public">
    <div class="catch-copy-container">
      <!-- <div>
        <img style="width: 100vw" src="./../../../assets/catch_copy.svg" />
      </div> -->
      <div
        class="catch-copy-sub container is-fluid"
        style="transform: translateY(30%); height: 100%"
      >
        <div style="font-size: 1.7rem; font-weight: 700; padding: 10px">
          大切な資産を適切に活用
        </div>
        <div
          style="
            font-size: 1rem;
            font-weight: 500;
            padding: 10px;
            text-align: center;
          "
        >
          ご相談いただいた内容を基に現地調査を行い、さまざまな観点から最適な方策をご提案いたします。
        </div>
      </div>
    </div>
    <!-- <div class="container is-fluid"> -->
    <!-- <div class="columns">
        <div
          class="column is-two-fifths"
          style="align-items: center; display: flex; transform: translateY(30%)"
        >
          <div style="font-size: 1.7rem; font-weight: 700; padding: 10px">
            空き家の〇〇、ご存知ですか？
          </div>
        </div>
        <div class="column is-three-fifths">
          <div style="padding: 5rem 1.5rem 2.5rem">
            <div
              style="font-size: 1.25rem; font-weight: 700; padding-bottom: 10px"
            >
              空き家を放置しておくと、固定資産税が6倍になる？
            </div>
            <div>
              〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇
            </div>
            <div>
              〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇
            </div>
          </div>
          <div style="padding: 2.5rem 1.5rem 2.5rem">
            <div
              style="font-size: 1.25rem; font-weight: 700; padding-bottom: 10px"
            >
              住む人の居なくなった家を放置しておくと、建物の劣化スピードが早い？
            </div>
            <div>
              〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇
            </div>
            <div>
              〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇
            </div>
          </div>
          <div style="padding: 2.5rem 1.5rem 5rem">
            <div
              style="font-size: 1.25rem; font-weight: 700; padding-bottom: 10px"
            >
              空き家は地域の環境や発展にも影響を及ぼしてしまう？
            </div>
            <div>
              〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇〇
            </div>
          </div>
        </div>
      </div> -->
    <!-- <div class="columns" style="height:50vh;background-color: #f5f5f5;">
        <div
          class="column is-full"
          style="align-items: center; display: flex;"
        >
          <div>
            <div
              style="font-size: 2rem; font-weight: 700; padding-bottom: 10px"
            >
              まずは一度お気軽にご連絡ください
            </div>
            <div>
              私たち「if」では、お話を伺い現地調査を行なった上で、建物とお客様の状況に合った最適な方策をご提案いたします。
            </div>
          </div>
        </div>
        <div class="column is-three-fifths" style="background-color: aqua">
          <div>現地調査からの流れアニメ</div>
        </div>
      </div> -->
    <!-- </div> -->
    <div
      class="unoccupied-house-suggestion"
    >
      <div
        class="columns unoccupied-house-suggestion-container"
      >
        <div class="column is-full unoccupied-house-suggestion-container-wrapper">
          <div
            class="unoccupied-house-suggestion-container-header"
          >
            ご相談からご提案までの流れ
          </div>
          <div
            class="unoccupied-house-suggestion-container-details"
          >
            私たち「if」では、お話を伺い現地調査を行なった上で、建物とお客様の状況に合った最適な方策をご提案いたします。
          </div>
          <div class="unoccupied-house-suggestion-container-images">
            <img
              style="width: 100%;max-width: 800px;"
              src="./../../../assets/unoccupied-house-image.svg"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="unoccupied-house-content">
      <div class="container is-fluid unoccupied-house-container">
        <div class="unoccupied-house-container-header">
          {{"現地調査に基づき、必要に応じてリフォーム・修繕等、ご提案させていただきます。\n一連の作業は全て自社施行であり、スムーズな対応をお約束します。"}}
        </div>
        <div class="columns is-multiline">
          <div class="column is-one-quarter">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <figure class="image is-3by2">
                  <img
                    src="./../../../assets/YqcK9t.png"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <div style="font-size: 1.25rem; font-weight: 700">
                    ハウスクリーニング
                  </div>
                  <div style="font-size: 1rem; font-weight: 400">
                    <div>
                      全体クリーニングから部分クリーニングまで(トイレ、浴室、窓、換気扇、エアコン等)施工いたします。
                    </div>
                    <div>ワンルームから戸建てまで、柔軟に対応いたします。</div>
                  </div>
                  <div style="font-size: 0.75rem; font-weight: 500"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <figure class="image is-3by2">
                  <img
                    src="./../../../assets/hokenkouji.png"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <div style="font-size: 1.25rem; font-weight: 700">
                    リフォーム工事
                  </div>
                  <div style="font-size: 1rem; font-weight: 400">
                    お客様の要望に合わせてのリフォームから当社にお任せのプランまで当社が責任持って施行いたします。
                  </div>
                  <div style="font-size: 0.75rem; font-weight: 500"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <figure class="image is-3by2">
                  <img
                    src="./../../../assets/gaikoukouji.png"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <div style="font-size: 1.25rem; font-weight: 700">
                    外構工事
                  </div>
                  <div style="font-size: 1rem; font-weight: 400">
                    屋根の吹き替え・塗装、外壁のサイディング張り替え工事・塗装、駐車場・駐輪場の整備等、施工いたします。
                  </div>
                  <div style="font-size: 0.75rem; font-weight: 500"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <figure class="image is-3by2">
                  <img
                    src="./../../../assets/tetsubi-kouji.png"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <div style="font-size: 1.25rem; font-weight: 700">
                    設備工事
                  </div>
                  <div style="font-size: 1rem; font-weight: 400">
                    給排水工事全般承ります。
                  </div>
                  <div style="font-size: 0.75rem; font-weight: 500"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <figure class="image is-3by2">
                  <img
                    src="./../../../assets/naisoukouji.png"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <div style="font-size: 1.5rem; font-weight: 700">
                    内装工事
                  </div>
                  <div style="font-size: 1rem; font-weight: 400">
                    壁紙、襖、障子、床材(フロアタイル等)施工いたします。
                  </div>
                  <div style="font-size: 0.75rem; font-weight: 500"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <figure class="image is-3by2">
                  <img
                    src="./../../../assets/zoen.png"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <div style="font-size: 1.25rem; font-weight: 700">
                    造園工事
                  </div>
                  <div style="font-size: 1rem; font-weight: 400">
                    草取り、庭木の剪定、抜根等お庭のこと全般承ります。
                  </div>
                  <div style="font-size: 0.75rem; font-weight: 500"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <figure class="image is-3by2">
                  <img
                    src="./../../../assets/1050041_m.jpg"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <div style="font-size: 1.25rem; font-weight: 700">
                    部屋の片付け
                  </div>
                  <div style="font-size: 1rem; font-weight: 400">
                    部屋の片付け・不用品処分、遺品整理等施工いたします。
                  </div>
                  <div style="font-size: 0.75rem; font-weight: 500"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <figure class="image is-3by2">
                  <img
                    src="./../../../assets/kaitaikouji.png"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <div style="font-size: 1.25rem; font-weight: 700">
                    解体工事
                  </div>
                  <div style="font-size: 1rem; font-weight: 400">
                    部分解体・内装解体、平屋からビルの解体工事まで施工いたします。
                  </div>
                  <div style="font-size: 0.75rem; font-weight: 500"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ToContactContent />
    <!-- <div class="container is-fluid">
      <div class="columns">
        <div class="column is-half">
          <div style="padding:3rem 1.5rem 3rem;">
            <div>最初に家を見つけて、家をすばやく見学しましょう</div>
            <div>Redfin Agentでツアーを即座に予約することで、他の購入者に先んじることができるように、5分ごとにリストを更新します</div>
          </div>
          <div style="padding:3rem 1.5rem 3rem;">
            <div>最初に家を見つけて、家をすばやく見学しましょう</div>
            <div>Redfin Agentでツアーを即座に予約することで、他の購入者に先んじることができるように、5分ごとにリストを更新します</div>
          </div>
          <div style="padding:3rem 1.5rem 3rem;">
            <div>最初に家を見つけて、家をすばやく見学しましょう</div>
            <div>Redfin Agentでツアーを即座に予約することで、他の購入者に先んじることができるように、5分ごとにリストを更新します</div>
          </div>
        </div>
        <div class="column is-half" style="align-items:center;display:flex;">
          <div style="font-size:2rem;font-weight:700;padding:10px;">なぜ私たちと一緒に購入するのですか？</div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { namespace } from "vuex-class";
import ToContactContent from '@/components/ToContactContent.vue';

const SystemModule = namespace("system");

@Options({
  components: {
    ToContactContent
  },
})
export default class UnoccupiedHouse extends Vue {
  public async mounted(): Promise<void> {
    window.scrollTo(0, 0);
    await this.updatePageLoadingState(false);
  }

  @SystemModule.Action("updatePageLoadingState")
  private updatePageLoadingState!: (state: boolean) => Promise<void>;

  private async routerView(to: string) {
    await this.updatePageLoadingState(true);
    this.$router.push(to);
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/util.scss";

.general-public {
  padding-top: 3.25rem;

  .catch-copy-container {
    height: 50vh;
    background: linear-gradient(
          130deg,
          rgba(231, 223, 223, 0.6),
          rgba(64, 65, 41, 0.5)
        )
        fixed,
      url("./../../../assets/chintai_1m.jpeg");
    // background: url("./../assets/YqcK9t.png");
    background-size: cover;
    background-position: center;
    text-align: center;
  }
}

.unoccupied-house-suggestion {
  text-align: left;
  padding: 30px;
  background-color: rgb(233 233 233);
  padding: 1rem 1.5rem 1rem;

  .unoccupied-house-suggestion-container {
    padding: 3rem 0rem 3rem;
    display: flex;
    align-items: center;

    .unoccupied-house-suggestion-container-wrapper {
      .unoccupied-house-suggestion-container-header {
        font-size: 1.7rem;
        font-weight: 700;
        padding-bottom: 10px;
      }

      .unoccupied-house-suggestion-container-details {
        padding-bottom: 30px;font-size: 1.35rem;
      }

      .unoccupied-house-suggestion-container-images {
        height: 100%;
        width: 100%;
        text-align: center;
      }

      @include sp {
        .unoccupied-house-suggestion-container-header {
          font-size: 5.4vw;
          font-weight: 700;
          padding-bottom: 10px;
        }

        .unoccupied-house-suggestion-container-details {
          padding-bottom: 30px;
          font-size: 3.5vw;
        }

        .unoccupied-house-suggestion-container-images {
          height: 100%;
          width: 100%;
          text-align: center;
        }
      }
   }
  }
}

.unoccupied-house-content {
  background-color: #fafafa;

  .unoccupied-house-container {
    padding: 2.5rem 1.5rem 5rem;

    .unoccupied-house-container-header {
      padding: 10px;
      padding: 1rem 0rem 2.5rem;
      text-align: left;
      font-size: 1.5rem;
      font-weight: 700;
      white-space: pre-wrap;
      word-wrap:break-word;
    }

    @include sp {
      .unoccupied-house-container-header {
        font-size: 4.5vw;
      }
    }
  }
}
</style>
